.notificationWrapper {
  z-index: 99999;
  position: fixed;
  bottom: 50px;
  right: -300px;
  max-width: 320px;
}

.notificationBox {
  position: relative;
  padding: 15px;
  // padding-left: 45px;
  // padding-bottom: 40px;
  background: #fce022;
  animation-name: slideLeft;
  animation-duration: 0.5s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
  .infoBox {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  .notificationMessage {
    font-family: "SF UI Display Medium", sans-serif;
    font-size: 16px;
    max-width: 85%;
  }
  .notificationButton {
    border: 1px solid black;
    border-radius: 10px;
    width: 100%;
    text-align: center;
    padding: 5px;
    font-size: 15px;
    outline: none;
    background: transparent;
    &:hover {
      background: white;
      cursor: pointer;
    }
  }
}

@keyframes slideLeftResponsive {
  0% {
    opacity: 0;
    margin-left: 160%;
  }
  100% {
    opacity: 1;
    margin-left: -310px;
  }
}

@keyframes slideLeft {
  0% {
    opacity: 0.5;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(-320px);
  }
}

@keyframes slideRight {
  0% {
    opacity: 1;
    transform: translateX(-320px);
  }
  100% {
    // opacity: 0.5;
    opacity: 1;
    transform: translateX(20px);
  }
}
