.spinner {
  position: relative;
  width: 100px;
  height: 100px;
  &.small {
    width: 35px;
    height: 35px;
  }
  &.extraSmall {
    width: 25px;
    height: 25px;
  }

  $size: 5px;
  $small: 3px;
  $xs: 2px;
  .dbl-spinner {
    position: absolute;
    width: 100px;
    height: 100px;
    &.small {
      width: 35px;
      height: 35px;
      border: $small solid transparent;
      border-top: $small solid #f8c923;
      border-left: $small solid #f8c923;
      -webkit-animation: 2s spin linear infinite;
      animation: 2s spin linear infinite;
    }
    &.extraSmall {
      width: 25px;
      height: 25px;
      border: $xs solid transparent;
      border-top: $xs solid #f8c923;
      border-left: $xs solid #f8c923;
      -webkit-animation: 2s spin linear infinite;
      animation: 2s spin linear infinite;
    }
    border-radius: 50%;
    background-color: transparent;
    border: $size solid transparent;
    border-top: $size solid #f8c923;
    border-left: $size solid #f8c923;
    -webkit-animation: 2s spin linear infinite;
    animation: 2s spin linear infinite;
  }

  .dbl-spinner--2 {
    border: $size solid transparent;
    border-right: $size solid #4b9bf5;
    border-bottom: $size solid #4b9bf5;
    -webkit-animation: 1s spin linear infinite;
    animation: 1s spin linear infinite;
    &.small {
      border: $small solid transparent;
      border-right: $small solid #4b9bf5;
      border-bottom: $small solid #4b9bf5;
      -webkit-animation: 1s spin linear infinite;
      animation: 1s spin linear infinite;
    }
    &.extraSmall {
      border: $xs solid transparent;
      border-right: $xs solid #4b9bf5;
      border-bottom: $xs solid #4b9bf5;
      -webkit-animation: 1s spin linear infinite;
      animation: 1s spin linear infinite;
    }
  }

  @keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}
