.btn {
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 50px;
  //max-height: 300px;
  border-radius: 8px;
  border: 0;
  outline: unset;
  background: #4b9bf5;
  color: #ffffff;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  cursor: pointer;
  .spinnerBox {
    position: absolute;
    width: 40px;
    height: 40px;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & span > .selectedLabels {
    display: flex;
    flex-direction: column;
    padding: 5px;
    span {
      margin: 5px 0;
    }
  }

  &.freeTopSpace {
    margin-top: 30px;
  }

  &:disabled {
    background: #f6f7fb;
    color: #b9b9b9;
  }

  &.secondary {
    @extend .btn;
    display: flex;
    padding: 14px;
    background: #f6f7fb;
    color: #272d2d;
    justify-content: space-between;
    align-items: center;
    span {
      display: flex;
      align-items: center;
      height: auto;
      img {
        margin-right: 10px;
        max-width: 34px;
      }
    }
    &:disabled {
      background: #f6f7fb;
      color: #b9b9b9;
    }
  }
  &.save {
    @extend .btn;
    display: flex;
    padding: 14px;
    background: #4b9bf5;
    color: white;
    justify-content: space-between;
    align-items: center;
    transition: all 0.15 ease-in-out;
    &:hover {
      background: #3488e9;
    }
    &:focus {
      background: #4b9bf5;
    }
    span {
      display: flex;
      align-items: center;
      height: auto;
      margin: 0 auto;
      img {
        margin-right: 10px;
        max-width: 34px;
      }
    }
    &:disabled {
      background: #f6f7fb;
      color: #b9b9b9;
    }
  }
  &.secondaryLock {
    @extend .btn;
    display: flex;
    padding: 14px;
    color: #4b9bf5;
    border: 1px solid #4b9bf5;
    background: #f6f7fb;
    margin-left: 1px;
    justify-content: space-between;
    align-items: center;
    transition: all 0.15s ease-in-out;
    &:hover {
      background: #4b9bf5;
      color: white;
    }
    &:focus {
      background: #4b9bf5;
      color: white;
    }
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      height: auto;
      margin: 0 auto;
      img {
        margin-right: 10px;
        max-width: 34px;
      }
    }
    &:disabled {
      background: #f6f7fb;
      color: #b9b9b9;
    }
  }
  &.dangerAnimation {
    @extend .btn;
    // background: #fd3845;
    border: 1px solid #fd3845;
    color: #fd3845;
    width: 100%;
    transition: all 0.15s ease-in-out;
    background: #f6f7fb;
    max-width: 140px;
    height: 40px;
    margin: 0 auto;
    &:hover {
      background: #fd3845;
      color: white;
    }
    &:focus {
      background: #fd3845;
      color: white;
    }
    &:disabled {
      background: #f6f7fb;
      color: #b9b9b9;
    }
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-right: 10px;
      }
    }
  }
  &.danger {
    @extend .btn;
    background: #fd3845;
    width: 100%;
    max-width: 140px;
    height: 40px;
    margin: 0 auto;
    &:disabled {
      background: #f6f7fb;
      color: #b9b9b9;
    }
    &:hover {
      background: #f82331;
    }
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-right: 10px;
      }
    }
  }
}
