.newConsultationWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 8px;
  height: calc(100% - 65px);
  width: 350px;
  padding: 20px;
  &.loading {
    opacity: 0.5;
  }
  .absoluteCenter {
    z-index: 9;
    opacity: 1;
    top: 30%;
  }
  // padding-top: 10px;

  .closeIcon {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 20px;
    top: 13px;
    &:hover {
      cursor: pointer;
    }
  }
  &.withFullScreenVideo {
    z-index: 155;
  }
  .setting {
    font-family: "SF UI Display Medium", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #272d2d;
    margin-bottom: 10px;
  }

  .formWrapper {
    height: 100%;
    max-height: 97%;
    .secondary.btn span {
      text-align: left;
    }
    form {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      height: 100%;
      max-height: 99%;
      overflow-x: hidden;
      align-items: center;
      scrollbar-width: none;
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: transparent;
        border-radius: 10px;
        background-color: transparent;
      }
      &::-webkit-scrollbar {
        width: 4px;
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #4b9bf5;
      }
      .fieldsWrapper {
        // max-height: 500px;
        height: 100%;
        display: flex;
        width: 100%;
        max-width: 310px;
        flex-direction: column;
        align-items: center;
        // max-height: 580px;
        max-height: 70%;
        overflow-y: auto;
        padding-right: 6px;
        &.loading {
          opacity: 0.5;
          pointer-events: none;
        }
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: transparent;
          border-radius: 10px;
          background-color: transparent;
        }
        &::-webkit-scrollbar {
          width: 4px;
          background-color: transparent;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: #4b9bf5;
        }
        .addressWrapper {
          .btn {
            margin-top: 15px;
          }
        }
        .btn {
          // margin-top: 20px;
          &:not(:first-child) {
            margin-top: 15px;
          }
          span {
            img {
              max-width: 34px;
              max-height: 34px;
              border-radius: 8px;
              width: fit-content;
            }
          }
          &.disabled {
            span {
              img {
                width: 22px;
              }
            }
          }
          &.secondary {
            span {
              // width: 100%;
            }
          }
        }
      }
    }
    .optionSelectingContainer.show {
      position: absolute;
      .formTitle {
        font-family: "SF UI Display Medium", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        color: #272d2d;
      }
      .periods {
        display: flex;
        justify-content: space-between;
        padding: 15px;
      }
      .filterOptions {
        .option {
          max-height: 70px;
        }
      }
    }

    .buttonWrapper {
      width: 310px;
      -webkit-box-shadow: 0px 5px 5px -7px rgba(34, 60, 80, 0.6) inset;
      -moz-box-shadow: 0px 5px 5px -7px rgba(34, 60, 80, 0.6) inset;
      box-shadow: 0px 5px 5px -7px rgba(34, 60, 80, 0.6) inset;
      .price {
        display: flex;
        justify-content: space-between;
        margin-top: 5px;
        // margin-bottom: 8px;
        padding: 0 5px;
        .cost {
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 24px;
          color: #272d2d;
        }
      }
      .btn {
        margin-top: 5px;
        &.btn-submit {
          background: #7dd952;
          &:disabled {
            background: #f6f7fb;
            color: #b9b9b9;
          }
        }
        &.btn-change {
          margin-top: 10px;
        }
        &.btn-cancel {
          margin-top: 5px !important;
        }
        &.danger {
          height: 50px;
          margin-top: 10px;
          max-width: 100%;
        }
      }
    }
  }
  .doneContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding-top: 50px;
    .contentWrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 60%;
      .doneImageContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: 22px;
      }
      .created {
        font-family: "SF UI Display Medium", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        color: #272d2d;
        text-align: center;
      }
      .doneContent {
        font-family: "SF UI Display Medium", sans-serif;
        font-style: normal;
        color: #272d2d;
        text-align: center;
        margin-top: 22px;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        width: 245px;
        p {
          margin-top: 5px;
          margin-bottom: 5px;
        }
      }
      .doneContentContainer {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
  }
}

#root
  > main
  > div
  > div
  > div.contentContainer
  > div
  > div
  > div.newConsultationContainer
  > div
  > div.formWrapper
  > form
  > div.fieldsWrapper
  > div.addressWrapper {
  width: 100%;
}

#root
  > main
  > div
  > div
  > div.contentContainer
  > div
  > div
  > div.newConsultationContainer
  > div
  > div.formWrapper
  > form
  > div.fieldsWrapper
  > div.addressWrapper
  > div
  > div.inputsContainer
  > form
  > .formField {
  width: 100%;
}
.titleWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .resetButtonBox {
    width: 100px;
    border-radius: 4px;
    background: #e5e5e5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
    padding-left: 5px;
    padding-right: 5px;
    height: 25px;
    &:hover {
      background: lightgrey;
      cursor: pointer;
    }
    .resetText {
      font-size: 15px;
      font-family: "SF UI Display Medium", sans-serif;
    }
  }
}
