.hospitalsDivisionsListWrapper {
  width: 100%;
  height: 100%;
  .pageHeader {
    display: flex;
    flex-direction: row;
    margin: 0 0 40px 0;
    .arrowLeft {
      margin-right: 20px;
      cursor: pointer;
    }
    .pageTitle {
      font-family: "SF UI Display Medium", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 38px;
      margin: 0;
    }
  }

  .hospitalsDivisionsItemsContainer {
    width: 100%;
    height: calc(100% - 120px);
    position: relative;
    max-width: 960px;
    overflow: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: start;
    align-content: baseline;
    border-radius: 8px;

    .hospitalsDivisionsItem {
      text-align: center;
      width: 23.9%;
      cursor: pointer;
      padding: 10px 15px;
      margin: 5px;
      background: #ffffff;
      border-radius: 8px;
      font-family: "SF UI Display Medium", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      color: #272d2d;
    }
  }

  .monitoringEmptyText {
    font-family: "SF UI Display Medium", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;
    color: #b9b9b9;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 120px);
  }
}
