.bannerFormContainer {
  width: 100%;
  height: 100%;
  max-width: 350px;
  position: absolute;
  display: flex;
  flex-direction: column;
  // right: -350px;
  padding: 15px;
  top: 0;
  right: 0;
  background: #ffffff;
  border-radius: 8px;
  animation: editBannerAnimation 0.2s ease-in-out;
  // &.isEditingBanner {
  //   right: 0;
  //   animation: editBannerAnimation 0.2s ease-in-out;
  // }
  .bannerFormTitleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .bannerFormTitle {
      opacity: 0.9;
      display: flex;
      align-items: center;
      margin-top: 10px;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      padding: 0 5px;
    }
    & > img {
      margin-top: 10px;
    }
  }
}
.specAvatarBanner {
  height: 50px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // margin-top: 30px;
  // height: 30px;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 8px;
  &:hover {
    background: #e5e5e5;
  }
  .cropContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cropBannerPhoto {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  .trashIcon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .fileName {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }
  .trashIcon img {
    width: 20px;
    height: 20px;
  }
  .trashIcon img:hover {
    // transform: scale(1.05);
    cursor: pointer;
  }
}
.descriptionEditContainer {
  border: 1px solid #e5e5e5;
  margin-top: 30px;
  border-radius: 8px;
  .formField {
    margin-bottom: 0px;
  }
}
.editingSectionTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  opacity: 0.6;
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  margin-top: 30px;
  padding: 0 5px;
  width: 100%;
  margin-bottom: 5px;
}
@keyframes editBannerAnimation {
  from {
    opacity: 0;
    right: -350px;
  }
  to {
    opacity: 1;
    right: 0px;
  }
}
