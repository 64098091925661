.wrappNewSubscriptionForm {
  position: relative;
  width: 100%;
  max-width: 350px;
  background: #ffffff;
  border-radius: 8px;

  .formTitle {
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    padding: 0 5px;
  }

  form {
    display: flex;
    flex-direction: column;
    height: calc(100% - 50px);
    .formField {
      margin: 10px 0;
      height: 50px;
    }
    .toggleFieldImg {
    }
    .toggleField {
      margin: 10px 0;
    }
  }

  form > button[type="button"] {
    height: auto;
  }
  .formField {
    .rdt {
    }

    .rdtPicker {
      right: 0;
    }
  }

  .toggleFieldImg {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 5px));
    grid-column-gap: 10px;
    margin: 10px 0;

    .optionItem {
      &:first-child {
        img {
          margin-top: 14px;
          margin-bottom: 14px;
        }
      }
    }
  }

  .specAvatar {
    display: flex;
    align-items: center;

    .fileName {
      margin: 0 10px;
    }

    .trashIcon {
      cursor: pointer;
    }

    .cropPhoto {
      height: 50px;
    }
  }

  .toggleField {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 5px));
    grid-gap: 10px;
    background-color: transparent;
    padding: 0;

    .optionItem {
      cursor: pointer;
      height: 40px;
      background-color: #f6f7fb;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #b9b9b9;
      border: 2px solid transparent;
      max-width: inherit;
      span {
        font-weight: bold;
        font-size: 18px;
      }
    }

    .active {
      border: 2px solid #4b9bf5;
      background-color: #fff;
      color: #4b9bf5;
      box-shadow: none;
    }
  }

  .dateRange {
    margin-top: 10px;
    .formField {
      .datePicker {
        .rdtPicker {
        }
      }
    }
  }

  .addingImg {
    position: absolute;
    right: 0;
    top: 0;
    background-color: #fff;
    z-index: 10;
    width: 350px;
    height: 100%;
    transform: translateX(100%);
    transition: all 0.4s ease;
    padding: 25px 15px;
  }

  .show {
    transition: all 0.4s ease !important;
    transform: translateX(0);
  }

  .sub-name {
    height: 50px;
    width: 100%;
    background-color: #f6f7fb;
    border-radius: 8px;
    border: none;
    font-size: 14px;
    padding: 0 14px;
  }

  .client-type {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 5px));
    grid-column-gap: 10px;

    &-item {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 132px;
      background-color: #f6f7fb;
      border-radius: 8px;
      color: #b9b9b9;

      &:first-child {
        img {
          margin-top: 14px;
          margin-bottom: 14px;
        }
      }
    }

    .type-active {
      color: #000;
    }
  }

  .duration-type {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 5px));
    grid-gap: 10px;

    &-item {
      cursor: pointer;
      height: 76px;
      background-color: #f6f7fb;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #b9b9b9;
      border: 2px solid transparent;

      span {
        font-weight: bold;
        font-size: 18px;
      }
    }

    .duration-active {
      border: 2px solid #4b9bf5;
      background-color: #fff;
      color: #4b9bf5;
    }
  }

  .sub-datepicker {
    display: flex;
    width: 100%;
    background-color: #f6f7fb;
    border-radius: 8px;
    flex-direction: column;
    justify-content: center;
    height: 50px;
    padding: 9px 0 9px 15px;

    span {
      color: #b9b9b9;
    }

    .datePicker {
      max-width: 100%;
      padding: 0;
    }

    &-wrap {
      display: flex;
      width: 100%;
      height: 20px;
      position: relative;

      span {
        color: #000;
        margin-right: 6px;
      }

      & > div {
        height: 20px;
        padding: 0;
      }

      img {
        margin-right: 10px;

        &[alt="close"] {
          cursor: pointer;
        }
      }
    }
  }

  .sub-selector {
    min-height: 50px;
    max-height: 100px;
    display: flex;
    position: relative;
    align-items: center;
    background-color: #f6f7fb;
    border-radius: 8px;
    padding: 0 18px;
    cursor: pointer;

    .specialities {
      display: flex;
      align-items: center;
      height: auto;
      line-height: 17px;
      width: calc(100% - 4px);
      margin-left: 0;
    }

    span {
      margin-left: 10px;
    }

    img[alt="arrow"] {
      position: absolute;
      right: 18px;
    }

    &-chosen {
      display: flex;
      flex-direction: column;

      span {
        font-size: 10px;
        line-height: 12px;
        color: #b9b9b9;
        margin-left: 0;
      }
    }
  }

  button {
    margin: 10px 0;
    width: 100%;
    border: none;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f6f7fb;
    border-radius: 8px;
    color: #b9b9b9;
  }

  .active-button {
    background-color: #4b9bf5;
    color: #fff;
  }
}

//new styles
.newSubscriptionForm {
  position: relative;
  width: 100%;
  max-width: 350px;
  padding: 15px;
  background: #ffffff;
  border-radius: 8px;
  .formTitle {
    display: flex;
    align-items: center;
    padding: 0 5px;
    margin-top: 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #272d2d;
  }
  .subscriptionForm {
    height: calc(100% - 65px);
    .formContent {
      height: 90%;
      .formField {
        height: 50px;
        margin: 10px 0;
      }
      .toggleFieldImg {
        display: grid;
        grid-template-columns: repeat(2, calc(50% - 5px));
        grid-column-gap: 10px;
        margin: 10px 0;
      }
      .addingImg {
        margin: 10px 0;
      }
      .toggleField {
        display: grid;
        grid-template-columns: repeat(2, calc(50% - 5px));
        grid-gap: 10px;
        background-color: transparent;
        padding: 0;

        .optionItem {
          cursor: pointer;
          height: 40px;
          background-color: #f6f7fb;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #b9b9b9;
          border: 2px solid transparent;
          max-width: inherit;
        }

        span {
          font-weight: bold;
          font-size: 18px;
        }

        .active {
          border: 2px solid #4b9bf5;
          background-color: #fff;
          color: #4b9bf5;
          box-shadow: none;
        }
      }
      .dateRange {
        margin: 10px 0;
      }
      button[type="button"] {
        height: auto;
      }
      .optionSelectingContainer {
        margin: 10px 0;
      }
    }
    .formFooter {
      height: 10%;
      // display: flex;
      // flex-direction: column;
      align-items: flex-end;
      // justify-content: flex-start;
    }
  }
}
.formTitleEdit {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  padding: 0 5px;
  margin-bottom: 30px;
  &.edit {
    @extend .formTitle;
    justify-content: space-between;
  }
  img {
    cursor: pointer;
  }
}
