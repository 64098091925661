.subscription-wrapper {
  display: grid;
  grid-template-columns: repeat(3, calc((100% / 3) - 20px));
  grid-gap: 20px;
  grid-auto-rows: min-content;
  margin-bottom: 20px;
  position: relative;
  width: 100%;
  overflow: auto;
  height: calc(100% - 138px);
  border-radius: 8px;
  &.showBanner {
    height: calc(100% - 298px);
  }

  .subscription-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 17px 20px;
    height: 100%;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    .optionContainer {
      display: flex;
      justify-content: space-between;
      height: 30px;
      .deleteOption,
      .editOption {
        width: 33px;
        height: 33px;
        display: flex;
        justify-content: center;
        align-items: center;
        & img {
          opacity: 0.8;
          transition: all 0.25s ease-in-out;
        }
        &:hover img {
          transform: scale(1.1);
          opacity: 1;
        }
      }
      .deleteOption {
        margin-right: 10px;
      }
    }
    &.editMode {
      opacity: 0.6;
      background: lightgrey;
      pointer-events: none;
    }
    // &.noEvents{

    // }
    &.editedItem {
      box-shadow: 0px 0px 14px 9px rgba(34, 60, 80, 0.2);
    }

    .subscription {
      &-header {
        font-size: 14px;
        line-height: 17px;
        font-weight: bold;
        color: #272d2d;
        opacity: 0.3;
      }

      &-title {
        font-size: 22px;
        font-weight: bold;
        line-height: 26px;
        color: #272d2d;
        max-height: 54px;
      }

      &-amount {
        font-size: 42px;
        line-height: 50px;
        font-weight: bold;
        color: #272d2d;
        margin-top: 24px;
        margin-bottom: 0;

        .subscription-patient {
          opacity: 0.3;
        }
      }
    }
  }
}

.empty-list {
  color: #b9b9b9;
  font-size: 28px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 110px);
}

.spinner {
  position: absolute;
  top: 50%;
  left: 35%;
  transform: translate(-50%, -50%);
}

.dashBoardContainerUnique {
  width: 100%;
  // overflow: auto;
  &::-webkit-scrollbar {
    width: 0;
  }
  scrollbar-width: none;
  .title {
    font-family: "SF UI Display Medium";
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 38px;
    //margin-top: 25px;
    margin-bottom: 30px;
  }
}
