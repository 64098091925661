.btn {
  position: relative;
  .btnLoadingBox {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
  }
}
.optionSelectingContainer {
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  max-width: 350px;
  padding: 20px 15px;
  height: 100%;
  background: white;
  border-radius: 8px;
  transition: 0.25s ease-in-out;
  display: none;
  overflow-y: auto;

  .background {
    position: absolute;
    opacity: 0.7;
    top: 0;
    left: 0;
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: #b9b9b9;
    z-index: 1000;
  }

  .background > .spinner {
    left: 50%;
  }

  .formTitle {
    margin-bottom: 25px;
    img {
      margin-right: 20px;
      cursor: pointer;
    }
  }
  &.show {
    display: block;
    left: 0;
    transition: 0.25s ease-in-out;
    z-index: 156;
  }
  .search {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
    width: 100%;
    border-radius: 8px;
    margin-bottom: 10px;
    input {
      background: #f6f7fb;
      width: 100%;
      // max-width: 265px;
      padding: 11px 15px;
      padding-left: 45px;
      border: 0;
      outline: unset;
    }
    img {
      position: absolute;
      width: 18px;
      height: 18px;
      top: 11px;
      left: 15px;
      z-index: 1;
    }
    .count {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      max-width: 65px;
      height: 30px;
      margin-bottom: 10px;
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        background: #4b9bf5;
        border-radius: 50%;
        height: 30px;
        width: 30px;
      }
    }
  }
  .filterOptions {
    position: relative;
    overflow: scroll;
    scrollbar-width: none;
    height: calc(100% - 110px);
    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 2px;
      background: unset;
    }
    &::-webkit-scrollbar-thumb {
      width: 4px;
      // height: 10%;
      border-radius: 2px;
      background: #4b9bf5;
    }
    .option {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      max-height: 57px;
      color: #272d2d;
      border-bottom: 1px solid #e7e7e7;
      border-radius: 1px;
      padding: 20px 0;
      &.center {
        text-align: center;
        font-weight: 800;
        justify-content: center;
      }
      span {
        display: flex;
        align-items: center;
        font-family: "SF UI Display Medium", sans-serif;
        // font-weight: 600;
        min-height: 22px;
        height: auto;
        line-height: 17px;
        margin-right: 10px;
        img {
          margin-right: 15px;
          width: 40px;
          height: 40px;
          background-color: #e5e5e5;
          border-radius: 8px;
        }
      }
      img {
        width: 20px;
        height: 20px;
      }
    }
    .empty {
      display: flex;
      justify-content: center;
    }
  }
}

.countSpecializations {
  margin-bottom: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  max-width: 65px;
  height: 25px;
  margin-left: auto;
  // margin-bottom: 10px;
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background: #4b9bf5;
    border-radius: 50%;
    height: 25px;
    width: 25px;
  }
}
