.bannerContainer {
  overflow: hidden;
  position: relative;
  height: 150px;
  width: calc(100% - 20px);
  margin-bottom: 10px;
  .subscriptionBanner {
    position: absolute;
    position: relative;
    left: 0;
    top: 0;
    height: 150px;
    height: 100%;
    width: 100%;
    background: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    // transition: animation cubic-bezier(0.39, 0.575, 0.565, 1);
    animation: bannerAnimation 0.5s ease-in;
    padding: 10px;
    // .closeImg {
    //   width: 100%;
    //   display: flex;
    //   justify-content: flex-end;
    //   padding: 1px;
    & .closeBannerIcon {
      position: absolute;
      top: 10px;
      right: 10px;
    }
    & .closeBannerIcon:hover {
      transform: scale(1.03);
      cursor: pointer;
    }
    & .editBannerIcon {
      position: absolute;
      top: 50%;
      right: 15px;
    }
    & .editBannerIcon:hover {
      transform: scale(1.03);
      cursor: pointer;
    }
    // }
    .bannerInfoContainer {
      display: flex;
      justify-content: space-between;
      padding-left: 30px;
      padding-top: 10px;
      padding-right: 40px;
      & img {
        height: 100px;
      }
      .descriptionContainer {
        width: 350px;
        // max-width: ;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        .textToCenter {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          & p {
            margin: 0;
            overflow-y: auto;
            max-height: 80px;
            word-wrap: break-word;
            font-family: "SF UI Display Medium", sans-serif;
            width: 350px;
          }
        }
        & div {
          font-family: "SF UI Display Medium", sans-serif;
          text-align: center;
          font-weight: 600;
          letter-spacing: 0.5px;
        }
        .descriptionTitle {
          font-family: "SF UI Display Medium";
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          color: #272d2d;
          opacity: 0.3;
          font-size: 17px;
          margin-bottom: 10px;
        }
      }
      .shortDescriptionContainer {
        // @extend .descriptionContainer;
        max-width: 220px;
        // max-width: ;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        .textToCenter {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .shortDescription {
            margin: 0;
            text-align: center;
            overflow-y: auto;
            max-height: 80px;
            word-wrap: break-word;
            font-family: "SF UI Display Medium", sans-serif;
            // width: 100%;
            max-width: 220px;
          }
        }
        .shortDescriptionTitle {
          font-family: "SF UI Display Medium";
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          color: #272d2d;
          opacity: 0.3;
          font-size: 17px;
          margin-bottom: 10px;
        }
      }
      .costContainer {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        .textToCenter {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .bannerPrice {
          font-family: "SF UI Display Medium", sans-serif;
          font-size: 20px;
          line-height: 24px;
          font-weight: bold;
        }
        & span img {
          height: 20px;
        }
        .priceTitle {
          font-family: "SF UI Display Medium";
          font-style: normal;
          font-weight: bold;
          font-size: 17px;
          line-height: 17px;
          color: #272d2d;
          opacity: 0.3;
          margin-bottom: 10px;
        }
      }
    }
    // animation-iteration-count: 1;
  }
}

@keyframes bannerAnimation {
  from {
    top: -100%;
  }
  to {
    top: 0px;
  }
}
