.siderContainer {
  width: 70px;
  background-color: white;
  border-radius: 8px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  scrollbar-width: none;
  .sectionListContainer {
    display: flex;
    flex-direction: column;
    padding: 10px;
    height: 100%;
    .sectionList {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: inherit;
      scrollbar-width: none;
      overflow: scroll;
      &:not(:first-child) {
        div {
          margin-top: 40px;
          .monitoringIcon {
            width: 44px;
            height: 44px;
          }
        }
      }
      & > div:first-child {
        margin-top: 0;
      }
      & > .menu_item_link {
        width: 44px;
        height: 44px;
        min-height: 44px;
        background: #e8f6f6;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        cursor: pointer;
        img {
          width: 18px;
        }
        &.active {
          background: #4b9bf5;
          img {
            filter: brightness(4);
          }
        }
      }
      & > .symptoms {
        img {
          width: 20px;
        }
      }
      & > .companies {
        img {
          width: 30px;
        }
      }

      img {
        cursor: pointer;
        //margin-bottom: 10px;
      }
    }
    .flexStart {
      justify-content: start;
    }
  }
  .siderLogo {
    cursor: pointer;
  }
}
