.fieldSimpleSelect {
  .simpleInput {
    height: 50px;
    border: 0;
    padding: 15px;
    outline: unset;
    width: 100%;
    height: 50px;
    background: #f6f7fb;
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #272d2d;
    position: relative;
    &::placeholder {
      color: #b9b9b9;
    }
  }

  .value {
    position: absolute;
    top: 17px;
    left: 15px;
    background: #f6f7fb;
    width: calc(100% - 22px);
  }

  & > img {
    position: absolute;
    top: 18px;
    right: 15px;
  }
  .scrollarea-content {
    z-index: 100;
  }
  .wrapperOptions {
    z-index: 10;
    .options {
      padding: 10px;
      background: white;
      border-radius: 8px;
      width: 100%;
      max-width: 315px;
      border: 1px solid #f6f7fb;
      .option {
        cursor: pointer;
        padding: 15px 5px;
        border-bottom: 1px solid #e7e7e7;
        border-radius: 2px;
        &:hover {
          background: rgba(211, 211, 211, 0.384);
        }
        &:last-child {
          border: none;
        }
      }
    }
  }
}
