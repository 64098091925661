.patients-search-list {
  // padding: 0 20px;
  max-height: 450px;
  overflow: hidden;

  .patients-search-item {
    width: 100%;
    padding: 20px 20px;
    display: flex;
    align-items: center;

    font-size: 16px;
    line-height: 19px;
    color: #272d2d;

    // padding: 20px 0;

    &:not(:last-child) {
      border-bottom: 1px solid #e7e7e7;
    }
    &:hover,
    &:focus {
      background: lightgrey;
      cursor: pointer;
    }

    .photo {
      width: 40px;
      height: 40px;
      object-fit: cover;
      border-radius: 8px;
      margin-right: 20px;
      background-color: #e5e5e5;
    }
    .name {
      width: 45%;
    }
    .gray {
      font-size: 14px;
      line-height: 17px;
      color: #b9b9b9;
      margin-right: 10px;
    }
    .type {
      margin-left: auto;
    }
  }
}
