.newHospitalAddressForm {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.panel-left {
  position: relative !important;
  max-width: 960px !important;
  width: 100% !important;
  padding-right: 20px !important;
}

.panel-right {
  display: block !important;
}
