.formFieldSimpleTextarea {
  display: block;
  max-width: 317px;
  padding: 15px;
  width: 100%;
  height: auto;
  background: #f6f7fb;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 20px;
  margin-top: 5px;
  padding-top: 17px;
  &:last-child {
    margin-bottom: 0px;
  }

  .simpleTextarea {
    color: #272d2d;
    min-height: 21px;
    width: 100%;
    resize: vertical;
    background: #f6f7fb;
    border-radius: 8px;
    border-width: 0;
    outline: unset;
    &::placeholder {
      color: #b9b9b9;
    }
  }

  .inputLabel {
    display: none;
  }

  .simpleTextarea:focus + .inputLabel {
    display: inherit;
  }
  .show ~ .inputLabel {
    display: none !important;
  }
  .errorRequire.show ~ .simpleInput {
    border: 1px solid #fd3845;
  }
  .errorRequire {
    color: #fd3845;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    position: absolute;
    background-color: #f6f7fb;
    top: 17px;
    left: 15px;
    z-index: 5;
    width: calc(100% - 16px);
    display: none;
  }
  .errorRequire.show {
    display: block;
  }
}
