.formFieldSimpleInput {
  width: 100%;
  position: relative;

  .inputLabel {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    position: absolute;
    font-size: 10px;
    top: 5px;
    left: 15px;
    color: #b9b9b9;
    display: none;
  }
  .simpleInput {
    border: 0;
    padding: 15px;
    outline: unset;
    width: 100%;
    height: 50px;
    background: #f6f7fb;
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #272d2d;
    &::placeholder {
      color: #b9b9b9;
    }
    &:focus::placeholder {
      color: #f6f7fb;
    }
    &::-webkit-calendar-picker-indicator {
      background: none;
    }
  }
  .errorRequire {
    color: #fd3845;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    position: absolute;
    background-color: #f6f7fb;
    top: 17px;
    left: 15px;
    z-index: 5;
    width: calc(100% - 16px);
    display: none;
  }
  .errorRequire.show {
    display: block;
    pointer-events: none;
  }
  .errorRequire.show + .errorMessage.show {
    display: none;
  }
  .errorMessage {
    display: none;
    color: #fd3845;
    position: absolute;
    font-weight: 500;
    font-size: 10px;
    line-height: 17px;
    background-color: #f6f7fb;
    top: 2px;
    z-index: 10;
    left: 15px;
    width: calc(100% - 22px);
  }
  .simpleInput:focus + .inputLabel {
    display: inherit;
  }
  .simpleInput:valid + .inputLabel {
    display: inherit;
  }
  .show ~ .inputLabel {
    display: none !important;
  }
  .errorMessage.show ~ .simpleInput,
  .errorRequire.show ~ .simpleInput {
    border: 1px solid #fd3845;
  }
  .errorMessage.show {
    display: block;
  }
}
