.specializationMappingWrapper {
  width: 100%;
  height: 100%;
  .pageTitle {
    font-family: 'SF UI Display Medium', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    margin: 0 0 40px 0;
  }
  .specializationMappingContainer {
    width: 100%;
    height: calc(100% - 78px);
    & > .spinner {
      left: 50%;
    }
    .buttonMultiChoiceContainer {
      display: flex;
      height: calc(100% - 50px);
      .buttonMultiChoiceArea {
        width: 50%;
        height: 100%;
        position: relative;
      }
    }
  }
}
.specializationMappingWrapper
  > .specializationMappingContainer
  > .buttonMultiChoiceContainer
  > .buttonMultiChoiceArea
  > .show {
  width: 100%;
  max-width: 100%;
}
