.pushNotificationsFormWrapper {
  height: 100%;

  .pageTitle {
    font-family: "SF UI Display Medium", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 38px;
    margin: 0 0 30px 0;
  }

  .pushNotificationsForm {
    width: 40%;
    height: calc(100% - 68px);
    display: flex;
    flex-direction: column;

    .formField {
      height: 60px;
      & > .fieldInput > .wrapperOptions > .scrollarea > .scrollarea-content > .options {
        max-width: 100% !important;
      }
    }
    .textArea,
    .fill {
      height: 150px !important;
      margin: 0 0 30px 0 !important;
      max-width: 100% !important;

      & > textarea {
        resize: vertical;
        height: 150px !important;
      }
    }

    .btn {
      margin-top: auto;
    }
  }
}
