.showRelativesWrapper {
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: absolute;
  z-index: 15;
  .showRelatives {
    position: relative;
    width: 468px;
    height: auto;
    max-height: 336px;
    background-color: white;
    border-radius: 8px;
    margin-left: 40px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    overflow-x: hidden;
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: transparent;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      background-color: transparent;
    }
    &::-webkit-scrollbar {
      width: 4px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      background-color: #4b9bf5;
    }
    &-unit {
      text-decoration: none;
      border-bottom: 1px solid #e7e7e7;
      height: 82px;
      display: flex;
      cursor: pointer;
      &-photo {
        width: 40px;
        height: 40px;
        margin: 20px 0 0 20px;
        border-radius: 8px;
      }
      &-replaceAva {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        margin: 20px 0 0 20px;
        border-radius: 8px;
        background-color: #f6f7fb;
        font-family: "SF UI Display Medium", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        color: #4b9bf5;
      }
      &-data {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 20px 0 0 20px;
        font-family: "SF UI Display Medium", sans-serif;
        height: 40px;
        font-style: normal;
        color: #272d2d;
        width: 260px;
        &-name {
          font-weight: bold;
          font-size: 16px;
          line-height: 19px;
        }
        &-phone {
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
        }
      }
      &-relation {
        margin: 20px 0 0 15px;
        font-family: "SF UI Display Medium", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        color: #4b9bf5;
      }
    }
    .shevronUp {
      position: absolute;
      top: 29px;
      right: 20px;
      width: 15px;
      height: 8px;
      cursor: pointer;
    }
  }
}
