.show-med-card {
  width: 100%;
  position: relative;
  max-width: 350px;
  border-radius: 8px;
  background-color: white;
  margin-right: 20px;
  padding: 20px;
  height: calc(100% - 65px);

  strong {
    display: inline-block;
    font-family: "SF UI Display Medium", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #272d2d;
    margin-bottom: 20px;
  }

  .tabs {
    background: #f6f7fb;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 10px 5px;
    margin-bottom: 25px;
    .tab {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      padding: 11px 0;
      cursor: pointer;
      // transition: all 300ms;
      margin: 0 5px;
      color: #b9b9b9;
      &.active {
        background: #ffffff;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        color: #272d2d;
      }
    }
  }

  .container {
    position: relative;
    height: 100%;
    max-height: calc(100% - 130px);
    overflow-y: auto;
    // padding: 10px 5px;
    .loadingBox {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: transparent;
      border-radius: 10px;
      background-color: transparent;
    }
    &::-webkit-scrollbar {
      width: 4px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #4b9bf5;
    }
  }

  .medcard-info {
    // margin: 30px 0;
    display: flex;
    flex-direction: column;
    //justify-content: space-between;
    form {
      height: 100%;
      display: flex;
      flex-direction: column;
      .formContent {
      }
      .formFooter {
      }
    }
    .medcard-content {
      height: 100%;
      margin-bottom: 20px;
      .medcard-field {
        .title {
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #b9b9b9;
        }
        .value {
          font-size: 14px;
          line-height: 17px;
          color: #272d2d;
          height: 37px;
          display: flex;
          align-items: center;
        }
        .separator {
          background: #e7e7e7;
          border-radius: 1px;
          height: 1px;
          margin-bottom: 20px;
        }
      }
    }

    .medcard-footer {
      .editCardButton {
        margin-bottom: 20px;
      }
      .deleteCardButton {
        height: 50px;
        max-width: 100%;
      }
    }
  }
}

.show-med-card {
  .container {
    .medcard-info {
      position: relative;
      .medcard-field {
        .title {
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #b9b9b9;
        }
        .value {
          font-size: 14px;
          line-height: 17px;
          color: #272d2d;
          height: 37px;
          display: flex;
          align-items: center;
        }
        .separator {
          background: #e7e7e7;
          border-radius: 1px;
          height: 1px;
          margin-bottom: 20px;
        }
      }
      .btn {
        margin-top: auto;
      }
    }
  }
}

.show-history {
  .history-item {
    background-color: #f6f7fb;
    border-radius: 8px;
    padding: 9px 15px;
    margin-bottom: 20px;

    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: space-between;
    &:last-child {
      margin-bottom: 0;
    }

    .info {
      div {
        font-size: 10px;
        line-height: 12px;
        color: #272d2d;
        &.doctor {
          font-size: 14px;
          line-height: 17px;
          margin-bottom: 3px;
        }
      }
    }

    img {
      opacity: 0.5;
    }
  }
}

.medCardEditForm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .medCardEditForm-content {
    .formField {
      .datePicker {
        width: 100%;
        max-width: 300px;
        border-radius: 8px;
      }
    }
  }
  .medCardEditForm-footer {
  }
}
