.simpleDatePicker {
  .form-control {
    border: 0;
    padding: 15px;
    outline: unset;
    width: 100%;
    height: 50px;
    background: #f6f7fb;
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #272d2d;
    &::placeholder {
      color: #b9b9b9;
    }
    &:focus::placeholder {
      color: #f6f7fb;
    }
    &::-webkit-calendar-picker-indicator {
      background: none;
    }
  }

  .rdtPicker {
    width: 100%;
  }

  .errorRequire {
    color: #fd3845;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    position: absolute;
    background-color: #f6f7fb;
    top: 17px;
    left: 15px;
    z-index: 5;
    width: calc(100% - 16px);
    display: none;
  }
  .errorRequire.show {
    display: block;
  }
  .errorRequire.show + .errorMessage.show {
    display: none;
  }
  .errorMessage {
    display: none;
    color: #fd3845;
    position: absolute;
    font-weight: 500;
    font-size: 10px;
    line-height: 18px;
    background-color: #f6f7fb;
    top: 1px;
    z-index: 10;
    left: 15px;
    width: calc(100% - 22px);
  }
  .form-control:focus + .inputLabel {
    display: inherit;
  }
  .form-control:valid + .inputLabel {
    display: inherit;
  }
  .show ~ .inputLabel {
    display: none !important;
  }
  .errorMessage.show ~ .rdt .form-control,
  .errorRequire.show ~ .rdt .form-control {
    border: 1px solid #fd3845;
  }
  .errorMessage.show {
    display: block;
  }
}
