.consultCard {
  width: 100%;
  width: 350px;
  min-width: 350px;
  position: relative;
  background: #ffffff;
  padding: 25px 15px 20px 15px;
  height: calc(100% - 65px);
  border-radius: 8px;
  .medCardTitle {
    height: 24px;
    font-weight: 700;
    font-size: 20px;
    margin: 0;
  }

  form {
    height: calc(100% - 65px);
    .toggleField {
      margin-top: 25px;
      margin-bottom: 20px;
    }

    .consultCartFormContent {
      height: calc(100% - 65px);
      overflow: auto;
      scrollbar-width: none;
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: transparent;
        border-radius: 10px;
        background-color: transparent;
      }
      &::-webkit-scrollbar {
        width: 4px;
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #4b9bf5;
      }
      .formContent {
        .emptyList {
          text-align: center;
        }
        .formFieldDoc.textAreaDoc textarea {
          width: 100%;
          height: 20px;
          box-sizing: border-box;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          padding: 0;
          line-height: 17px;
          color: #272d2d;
        }
        .formField {
          &:first-child {
            margin-top: 0;
          }
        }
        .buttonsAndInputsArea {
          width: 100%;
          padding-bottom: 15px;
          .buttonMultiChoiceArea {
            margin: 10px 0;

            button {
              min-height: 50px;
              height: auto;
              background-color: #f6f7fb;
              border-radius: 8px;
              width: 310px;
              //margin: 10px 0;
              overflow: auto;
            }
            .btn.secondary {
              height: auto;
              min-height: 50px;
              span {
                background-color: #f6f7fb;
                width: 100%;
                text-align: left;
              }
            }
            .optionSelectingContainer.show {
              left: 0;
              top: 0;
              // height: auto;
              height: 100%;
              z-index: 200;
              .filterOptions {
                .option {
                  max-height: unset;
                }
              }
            }
          }
          .saveCancelBtns {
            display: flex;
            justify-content: space-between;
            width: 310px;
            margin-top: 10px;
            // margin-bottom: 20px;
          }
          .formItemError {
            color: #fd3845;
          }
        }
      }
    }
  }

  &.fullscreen {
    position: fixed;
    top: 1vh;
    right: 1vw;
    z-index: 145;
    background-color: #ffffff;
    height: 98vh;

    .consultCartFormContent .formContent {
      height: 78vh;
    }
  }
}
.fieldStyle {
  margin: 0 auto;
  width: 310px;
  //margin-bottom: 5px;
}

.finishForClientDisActive,
.finishForClientActive {
  margin-top: 40px;
  width: 310px;
  height: 50px;
  border-radius: 8px;
  font-family: "SF UI Display Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  outline: none;
  border: none;
}
.finishForClientActive {
  position: relative;
  .spinnerBox {
    position: absolute;
    right: 20px;
    top: 50%;
  }
  background-color: #4b9bf5;
  color: white;
  &.disabled {
    color: #b9b9b9;
    background: #f6f7fb;
  }
}
.hidden {
  display: none;
}
.cancel {
  position: relative;
  .spinnerBox {
    position: absolute;
    right: 20px;
    top: 50%;
  }
}
.save,
.cancel,
.cancelExtended {
  width: 100%;
  height: 50px;
  background: #4b9bf5;
  border-radius: 8px;
  border: none;
  outline: none;
  font-family: "SF UI Display Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}
.cancel,
.cancelExtended {
  background: #fd3845;
  &:disabled {
    background: #f6f7fb;
    color: #b9b9b9;
  }
}
.cancelExtended {
  width: 100%;
}

.recommend {
  font-family: "SF UI Display Medium", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  margin: 30px 0;
  text-align: center;
  color: #272d2d;
}

//Styles and DOMTree for CardDataPage
.consultCard {
  .medCardTitle {
  }
  form {
    .toggleField {
    }
    .consultCartFormContent {
      .formContent {
        .buttonsAndInputsArea {
          padding-bottom: 0;
          .formField {
            padding: 15px;
            margin: 15px 0;
            &:first-child {
              margin-top: 0;
            }
            &:last-child {
              margin-bottom: 0;
            }
            textarea {
              &:disabled {
                color: #b9b9b9;
              }
            }
            .textArea {
              &::-webkit-input-placeholder {
                vertical-align: middle;
              }
            }
          }
        }
      }
    }
  }
}

//Styles and DOMTree for AnamnezPage
.consultCard {
  form {
    .consultCartFormContent {
      .formContent {
        .dd1 {
          display: block;
          &.none {
            display: none;
          }
          & .formField {
            padding: 0;
          }
        }

        .dd2 {
          display: block;
          &.none {
            display: none;
          }
        }
        .formField {
          padding: 15px;
          margin: 15px 0;
          &:first-child {
            margin-top: 0;
          }
          &:last-child {
            margin-bottom: 0;
          }
          textarea {
            &:disabled {
              color: #b9b9b9;
            }
          }
          textarea {
            &::-webkit-input-placeholder {
              vertical-align: middle;
            }
          }
        }
      }
    }
  }
}

.dd1 {
  display: block;
  &.none {
    display: none;
  }
}

.dd2 {
  display: block;
  &.none {
    display: none;
  }
}
