.subscription {
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: relative;
  .subscriptionListBanner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

// .bannerFormContainer {
//   width: 100%;
//   height: 100%;
//   max-width: 350px;
//   position: absolute;
//   right: -350px;
//   top: 0;
//   background: #ffffff;
//   border-radius: 8px;
//   &.isEditingBanner {
//     right: 0;
//     animation: editBannerAnimation 0.2s ease-in-out;
//   }
// }

// @keyframes editBannerAnimation {
//   from {
//     opacity: 0;
//     right: -350px;
//   }
//   to {
//     opacity: 1;
//     right: 0px;
//   }
// }
