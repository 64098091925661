.consultaleListContainer {
  width: 100%;
  max-width: 960px;
  padding-right: 20px;
  position: relative;

  .absoluteCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .patientsSearchKeyBox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    .searchKey {
      font-family: "SF UI Display Medium", sans-serif;
      font-size: 23px;
      margin-right: 15px;
    }
  }

  .titleWithFilters {
    display: flex;
    //margin-top: 20px;
    margin-bottom: 30px;
    align-items: center;
    .title {
      font-family: "SF UI Display Medium", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 38px;
      //margin-top: 25px;
      margin-right: 40px;
    }
    .filters {
      display: flex;
      align-items: center;
      //margin-top: 25px;
      width: 100%;
      .filter {
        border: 1px solid #bec0c0;
        font-size: 12px;
        border-radius: 15px;
        color: #bec0c0;
        height: 30px;
        display: flex;
        align-items: center;
        padding: 0 15px;
        margin-right: 10px;
        &.active {
          border: 1px solid #4b9bf5;
          background: #4b9bf5;
          color: white;
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .consultationItemsContainer {
    height: calc(100% - 120px);
    overflow: auto;
    border-radius: 8px;
    scrollbar-width: none;
    .consultationPeriod {
      .date {
        padding-left: 5px;
        margin-bottom: 25px;
        font-family: "SF UI Display Medium", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: #272d2d;
      }

      .orderId {
        color: #4b9bf5;
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        span {
          font-style: normal;
          font-size: 14px;
          line-height: 17px;
        }
      }

      .consultationItem {
        position: relative;
        cursor: pointer;
        display: flex;
        width: 100%;
        padding: 15px 20px;
        height: 1px;
        min-height: 131px;
        background: #ffffff;
        border-radius: 8px;
        margin-bottom: 20px;
        transition: 0.15s ease-in-out;
        &.home {
          min-height: 145px;
        }
        .paymentNumber {
          position: absolute;
          bottom: 10px;
          left: 20px;
          color: #4b9bf5;
        }
        &.editMode {
          opacity: 0.6;
        }
        &.editItem {
          box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
        }
        .title {
          font-family: "SF UI Display Medium";
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          color: #272d2d;
          opacity: 0.3;
          // margin-bottom: 15px;
        }
        .id,
        .doc,
        .cause,
        .spec,
        .patient,
        .status,
        .action {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        .actionHome {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        .idInfo,
        .docInfo,
        .causeInfo,
        .specInfo,
        .patientInfo,
        .statusInfo {
          display: flex;
          align-items: center;
          height: 100%;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          color: #272d2d;
        }
        .id {
          width: 10%;
        }
        .patient {
          width: 33%;
          .patientInfo {
            .details {
              display: flex;
              flex-direction: column;
              .name {
                font-weight: bold;
              }
              .insuranceCompanyName {
                margin: 0;
                color: #b9b9b9;
                font-weight: bold;
              }
              .address {
                color: #4b9bf5;
                margin-top: 5px;
                max-width: 190px;
                // display: flex;
                display: inline-block;
                line-height: 20px;
                // vertical-align: middle;
                // flex-direction: column;
                // justify-content: center;
                // align-items: center;
              }
            }

            img {
              width: 40px;
              height: 40px;
              margin-right: 15px;
              border-radius: 8px;
              background-color: #e5e5e5;
            }
          }

          &:last-child {
            margin: 0;
          }
        }
        .doc {
          width: 32%;
          .docInfo {
            img {
              width: 40px;
              height: 40px;
              margin-right: 15px;
              border-radius: 8px;
              background-color: #e5e5e5;
            }
            .details {
              display: flex;
              flex-direction: column;
              .name {
                font-weight: bold;
              }
              .spec {
                color: #4b9bf5;
                margin-top: 4px;
              }
            }
          }
        }
        .cause {
          width: 17%;
          .causeInfo {
            height: 100%;
            display: block;
            padding-top: 34px;
            padding-right: 5px;
            overflow: scroll;
            overflow-wrap: break-word;
          }
        }
        .spec {
          width: 15%;
          .causeInfo {
            height: 100%;
          }
        }
        margin-bottom: 25px;
        .status {
          width: 15%;
          .statusInfo {
            flex-direction: column;
            align-items: start;
            justify-content: center;
            .time {
              font-size: 20px;
              font-weight: 700;
              margin-bottom: 10px;
            }
            .statusRecord {
              &.new {
                padding: 5px 25px;
                background: #fce022;
                border-radius: 15px;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 14px;
                color: #272d2d;
              }
              &.in_process {
                @extend .new;
                background: #52d9d1;
                color: #ffffff;
              }
              &.closed {
                @extend .new;
                background: #7dd952;
                color: #ffffff;
              }
              &.cancelled {
                @extend .new;
                background: #fd3845;
                color: #ffffff;
              }
              &.filled {
                @extend .new;
                background: #4b9bf5;
                color: #ffffff;
              }
            }
          }
        }
        .action {
          width: 9%;
          align-items: flex-end;
          justify-content: center;
          // margin-top: 10px;
        }
        .actionHome {
          width: 9%;
          align-items: center;
          flex-direction: row;
          justify-content: space-between;
        }
      }
    }
    .createClientButton {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: 0;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      a {
        text-decoration: none;
      }
    }
    p {
      display: inline-block;
      text-align: center;
      width: 100%;
      font-family: "SF UI Display Medium", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 33px;
      color: #b9b9b9;
      &.monitoringEmptyText {
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(100% - 110px);
      }
    }
    .btn {
      width: 275px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        display: flex;
        align-items: center;
        font-family: "SF UI Display Medium", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #ffffff;
        img {
          margin-right: 15px;
        }
      }
    }
  }
}
.editContainer {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    transform: scale(1.05);
  }
}
