.foundDoctorsWrapper,
.notFound {
  width: calc(100% - 370px);
  height: auto;
  min-width: 600px;
  background-color: white;
  border-radius: 8px;
  position: absolute;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  margin-top: -10px;
  z-index: 200;
  max-height: 450px;

  .doctorUnit {
    height: auto;
    min-height: 90px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e7e7e7;
    padding: 0 30px;
    &:last-of-type {
      border-bottom: none;
    }
    .docPhotoAndName {
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      .doctorPhoto {
        width: 40px;
        height: 40px;
        border-radius: 8px;
        background: #bec0c0;
      }
      .doctorName {
        display: block;
        font-family: "SF UI Display Medium", sans-serif;
        font-style: normal;
        font-size: 16px;
        line-height: 19px;
        color: #272d2d;
      }
    }
    .smallContent {
      display: flex;
      justify-content: space-between;
      width: 60%;
      .specialtyContent {
        &-unit {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          height: 50px;
          .specialty {
            font-family: "SF UI Display Medium", sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 19px;
            color: #272d2d;
          }
          .doctorSpecialtyImage {
            width: 40px;
            height: 40px;
          }
        }
      }
      .phoneContent {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 40%;
        margin-left: auto;
        .phone,
        .phoneNumber {
          font-family: "SF UI Display Medium", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          color: #272d2d;
        }
        .phone {
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #b9b9b9;
        }
        .phoneNumber {
          margin-left: 10px;
        }
      }
    }
  }
}
.notFound {
  height: auto;
  min-height: 87px;

  &.emptyList {
    min-height: 165px;
  }
  .text {
    font-family: "SF UI Display Medium", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    color: #b9b9b9;
    margin: 30px 0 0 30px;
  }
  a {
    text-decoration: none;
    .btn.alignCenter.flex {
      width: 275px;
      margin: 25px 0 0 30px;
      display: flex;
      justify-content: center;
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        // justify-content: space-between;
        // width: 200px;
        // margin-left: 35px;
        // padding-top: 3px;
        line-height: 17px;
        font-family: "SF UI Display Medium", sans-serif;
        font-style: normal;
        img {
          margin-right: 15px;
        }
      }
    }
  }
}
