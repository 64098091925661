.networkErrorPopupWrapper {
  position: absolute;
  bottom: 10%;
  left: -100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  padding: 15px;
  min-width: 325px;
  min-height: 75px;
  background: #fd3845;
  border-radius: 8px;
  z-index: 9999;
  &.succes {
    @extend .networkErrorPopupWrapper;
    background: #07bc0c;
  }
  &.slideIn {
    animation-name: slideIn;
    animation-duration: 0.5s;
    bottom: 10%;
    left: 3%;
    opacity: 1;
  }

  &.slideOut {
    animation-name: slideOut;
    animation-duration: 0.5s;
    bottom: 10%;
    left: -100%;
  }

  .networkErrorPopupContent {
    font-family: "SF UI Display Light";
    font-size: 14px;
    align-items: center;
    opacity: 1;
    color: white;
  }
}

@keyframes slideIn {
  from {
    left: -100%;
    opacity: 0;
  }

  to {
    left: 3%;
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    left: 3%;
    opacity: 1;
  }

  to {
    left: -100%;
    opacity: 0;
  }
}
