.sheduleListContainer {
  width: 100%;
  position: relative;
  max-width: 960px;
  padding-right: 20px;
  overflow: auto;

  .absoluteCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &::-webkit-scrollbar {
    width: 0;
  }
  scrollbar-width: none;
  .titleWithFilters {
    display: flex;
    margin-bottom: 30px;
    //margin-top: 20px;
    .title {
      font-family: "SF UI Display Medium";
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 38px;
      margin-right: 40px;
    }
    .filters {
      display: flex;
      align-items: center;
      width: 100%;
      .filter {
        border: 1px solid #bec0c0;
        font-size: 12px;
        border-radius: 15px;
        color: #bec0c0;
        height: 30px;
        display: flex;
        align-items: center;
        padding: 0 15px;
        margin-right: 10px;
        cursor: pointer;
        &.active {
          border: 1px solid #4b9bf5;
          background: #4b9bf5;
          color: white;
        }
      }
    }
    .btn {
      padding: 0;
      height: auto;
      background: none;
      max-width: 150px;
      span {
        display: flex;
        align-items: center;
        line-height: 22px;
        .btn-icon {
          width: 30px;
          border-radius: 8px;
        }
      }
    }
  }

  .sheduleItemsContainer {
    width: 100%;
    overflow: auto;
    height: calc(100% - 120px);
    border-radius: 8px;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 0px;
      border-radius: 2px;
      background: unset;
    }
    &::-webkit-scrollbar-thumb {
      width: 0px;
      height: 10%;
      border-radius: 2px;
      background: #4b9bf5;
    }
    .shedulePeriod {
      .date {
        padding-left: 5px;
        margin-bottom: 25px;
        font-family: "SF UI Display Medium", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: #272d2d;
      }
      .sheduleItem {
        cursor: pointer;
        display: flex;
        width: 100%;
        padding: 15px 20px;
        height: 1px;
        min-height: 131px;
        background: #ffffff;
        border-radius: 8px;
        margin-bottom: 20px;
        transition: 0.15s ease-in-out;
        &.editMode {
          opacity: 0.6;
        }
        &.editItem {
          box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
        }
        .title {
          font-family: "SF UI Display Medium";
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          color: #272d2d;
          opacity: 0.3;
          // margin-bottom: 15px;
        }
        .id,
        .doc,
        .shift,
        .duration,
        .action {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        .idInfo,
        .docInfo,
        .shiftInfo,
        .durationInfo {
          display: flex;
          align-items: center;
          height: 100%;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          color: #272d2d;
        }
        .id {
          width: 10%;
        }
        .doc {
          width: 40%;
          .docInfo {
            img {
              width: 40px;
              height: 40px;
              margin-right: 15px;
              border-radius: 8px;
              background-color: #e5e5e5;
            }
            .details {
              display: flex;
              flex-direction: column;
              .name {
                font-weight: bold;
              }
              .spec {
                color: #4b9bf5;
                margin-top: 4px;
              }
            }
          }
        }
        .shift {
          width: 25%;
        }
        .duration {
          width: 15%;
        }
        .action {
          width: 10%;
          align-items: flex-end;
          justify-content: center;
          // margin-top: 10px;
        }
        &:last-child {
          margin: 0;
        }
      }
      margin-bottom: 25px;
    }

    .sheduleEmptyMessage {
      align-items: center;
      font-family: "SF UI Display Medium";
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 33px;
      color: #b9b9b9;
      display: flex;
      justify-content: center;
      align-items: center;
      height: calc(100% - 110px);
    }

    //&.empty {
    //  display: flex;
    //  justify-content: center;
    //  align-items: center;
    //  height: calc(100% - 110px - 60px);
    //}
  }
}
