.promoCodeItemsContainer {
  width: 100%;
  height: calc(100% - 120px);
  position: relative;
  max-width: 960px;
  overflow: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  align-content: baseline;
  border-radius: 8px;

  .newPromocodeItem {
    position: relative;
    font-family: "Roboto", sans-serif;
    width: 100%;
    border-radius: 8px;
    padding: 10px;
    padding-top: 15px;
    background: white;
    // min-height: 120px;
    display: flex;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    .status {
      width: 100%;
      position: absolute;
      top: 0px;
      left: 50%;
      transform: translateX(-50%);
      height: 5px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      opacity: 0.4;
      &.green {
        background: green;
      }
      &.red {
        background: red;
      }
    }
    .successBox {
      position: absolute;
      top: 20px;
      left: 16px;
      width: 10px;
      height: 10px;
    }
    .mainInfoBox {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      margin-right: 10px;
    }
    .sectionTitle {
      position: absolute;
      top: 0px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 8px;
    }
    .sectionText {
      font-size: 14px;
      font-weight: 500;
      max-width: 100px;
      min-width: 90px;
    }
    .sectionBox {
      position: relative;
      padding-top: 15px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 100%;
      padding-right: 7px;
      padding-left: 7px;
      // border-left: 1px solid rgba(0, 0, 0, 0.281);
      border-right: 1px solid rgba(0, 0, 0, 0.281);
      &.wide {
        min-width: 120px;
        .sectionTitle {
          width: 100%;
        }
      }
      &.small {
        max-width: 70px;
        min-width: 70px;
      }
      &:first-child {
        border-left: 1px solid rgba(0, 0, 0, 0.281);
      }
      // margin-right: 10px;
    }
    // .dateBox{
    //   @extend .insuranceBox;
    //   .date{
    //     @extend .insurance;
    //   }
    // }
    .type {
      font-size: 15px;
      font-weight: 500;
      text-transform: uppercase;
    }
    .codeBox {
      position: relative;
      border-radius: 8px;
      padding-left: 10px;
      padding-right: 50px;
      padding-top: 15px;
      padding-bottom: 15px;
      text-align: center;
      -webkit-box-shadow: 2px 2px 8px 0px rgba(34, 60, 80, 0.2);
      -moz-box-shadow: 2px 2px 8px 0px rgba(34, 60, 80, 0.2);
      box-shadow: 2px 2px 8px 0px rgba(34, 60, 80, 0.2);
      width: 200px;
      overflow: hidden;
      margin-bottom: 10px;
      .promocode {
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
      }
      .copyToClipboard {
        position: absolute;
        height: 100%;
        top: 50%;
        right: 0px;
        transform: translateY(-50%);
        width: 50px;
        background: #4b9af58f;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom-right-radius: 8px;
        border-top-right-radius: 8px;
        &:hover {
          cursor: pointer;
          -webkit-box-shadow: 2px 2px 8px 0px #4b9bf5 inset;
          -moz-box-shadow: 2px 2px 8px 0px #4b9bf5 inset;
          box-shadow: 2px 2px 8px 0px #4b9bf5 inset;
          // border: 1px solid #4b9bf5;
        }
      }
    }
  }
  .promocodeItem {
    text-align: center;
    width: 23.9%;
    cursor: pointer;
    padding: 10px 15px;
    margin: 5px;
    background: #ffffff;
    border-radius: 8px;
    font-family: "SF UI Display Medium", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #272d2d;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .promocodesSpecializations {
      margin-top: 10px;
    }
    .expiration {
      width: 100%;
      margin: 0 auto;
      margin-bottom: 10px;
    }
    .buttonContainer {
      width: 100%;
      display: flex;
      justify-content: center;
      .deletePromocodeButton {
        width: 100px;
        display: flex;
        justify-content: center;
        //font-size: 12px !important;
        border: 1px solid rgba(75, 155, 245, 0.7);
        background-color: rgba(75, 155, 245, 0.7);
        color: white;
        border-radius: 15px;
        height: 30px;
        align-items: center;
        padding: 0 15px;
        margin-right: 10px;
        &:hover {
          cursor: pointer;
          background-color: rgba(75, 155, 245, 1);
        }
      }
    }
    .item-status {
      &.green {
        color: #07bc0c;
      }

      &.red {
        color: #fd3845;
      }
    }
  }
}
