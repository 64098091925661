.fieldInput {
  width: 100%;
  position: relative;
  .inputLabel {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    position: absolute;
    font-size: 10px;
    top: 5px;
    left: 15px;
    color: #b9b9b9;
  }
  input {
    border: 0;
    // padding: 24px 15px 14px 15px;
    padding: 15px;
    outline: unset;
    width: 100%;
    height: 50px;
    background: #f6f7fb;
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #272d2d;
    &::placeholder {
      color: #b9b9b9;
    }
    &::-webkit-calendar-picker-indicator {
      background: none;
    }
    &.withError {
      border: 1px solid red;
    }
  }

  &.error {
    border: 1px solid #fd3845;
    border-radius: 8px;
  }
}
.formField {
  .formItemError {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0px;
  }
}

.formFieldTwoSection {
  width: 100%;
  display: grid;
  grid-template-columns: 7fr 4fr;
  margin-bottom: 10px;
  input {
    border: 0;
    padding: 15px;
    outline: unset;
    width: 100%;
    height: 40px;
    background: #f6f7fb;
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #272d2d;
  }
  .inputTitle {
    display: flex;
    align-items: center;
    text-align: left;
  }
}
