.optionsContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 350px;
  padding: 20px 15px;
  height: 100%;
  background: white;
  border-radius: 8px;
  transition: 0.25s ease-in-out;
  display: none;
  &.shown {
    display: block;
    transition: 0.25s ease-in-out;
    z-index: 1;
    .formTitleDate {
      display: flex;
      justify-content: space-between;
      width: 250px;
      font-family: "SF UI Display Medium", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      color: #272d2d;
    }
  }
  .periods {
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
    width: 310px;
    height: calc(100% - 20px);
    overflow: auto;
    margin: auto;
    .emptyDateList {
      font-size: 14px;
      display: flex;
      justify-content: center;
      margin-top: 20px;
      width: 100%;
    }
    .dates,
    .hours {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 150px;
      height: fit-content;
      background: #f6f7fb;
      border-radius: 8px;
      padding: 10px;
      .date,
      .hour {
        display: flex;
        justify-content: center;
        width: 130px;
        padding: 12px 0;
        border-radius: 8px;
        cursor: pointer;
        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
        }
        &.active {
          max-width: 130px;
          background: white;
          border-radius: 8px;
          height: 40px;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
          cursor: pointer;
        }
      }
    }
  }
  .emptyDateList {
    font-size: 14px;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
