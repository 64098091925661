.dot-carousel {
  position: relative;
  left: -9999px;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: #4b9bf5;
  color: #4b9bf5;
  box-shadow: 9984px 0 0 0 #4b9bf5, 9999px 0 0 0 #4b9bf5, 10014px 0 0 0 #4b9bf5;
  animation: dotCarousel 1.5s infinite linear;
}

@keyframes dotCarousel {
  0% {
    box-shadow: 9984px 0 0 -1px #4b9bf5, 9999px 0 0 1px #4b9bf5,
      10014px 0 0 -1px #4b9bf5;
  }
  50% {
    box-shadow: 10014px 0 0 -1px #4b9bf5, 9984px 0 0 -1px #4b9bf5,
      9999px 0 0 1px #4b9bf5;
  }
  100% {
    box-shadow: 9999px 0 0 1px #4b9bf5, 10014px 0 0 -1px #4b9bf5,
      9984px 0 0 -1px #4b9bf5;
  }
}
