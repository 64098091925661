/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: "SF UI Display Ultralight";
  font-style: normal;
  font-weight: normal;
  src: local("SF UI Display Ultralight"),
    url("sf-ui-display-ultralight-58646b19bf205.woff") format("woff");
}

@font-face {
  font-family: "SF UI Display Thin";
  font-style: normal;
  font-weight: normal;
  src: local("SF UI Display Thin"),
    url("sf-ui-display-thin-58646e9b26e8b.woff") format("woff");
}

@font-face {
  font-family: "SF UI Display Light";
  font-style: normal;
  font-weight: normal;
  src: local("SF UI Display Light"),
    url("sf-ui-display-light-58646b33e0551.woff") format("woff");
}

@font-face {
  font-family: "SF UI Display Medium";
  font-style: normal;
  font-weight: normal;
  src: local("SF UI Display Medium"),
    url("SFUIDisplay-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "SF UI Display Semibold";
  font-style: normal;
  font-weight: normal;
  src: local("SF UI Display Semibold"),
    url("sf-ui-display-semibold-58646eddcae92.woff") format("woff");
}

@font-face {
  font-family: "SF UI Display Bold";
  font-style: normal;
  font-weight: normal;
  src: local("SF UI Display Bold"),
    url("sf-ui-display-bold-58646a511e3d9.woff") format("woff");
}

@font-face {
  font-family: "SF UI Display Heavy";
  font-style: normal;
  font-weight: normal;
  src: local("SF UI Display Heavy"),
    url("sf-ui-display-heavy-586470160b9e5.woff") format("woff");
}

@font-face {
  font-family: "SF UI Display Black";
  font-style: normal;
  font-weight: normal;
  src: local("SF UI Display Black"),
    url("sf-ui-display-black-58646a6b80d5a.woff") format("woff");
}
