.error-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  .error-logo {
    display: block;
    margin: 15px auto;
    width: 250px;
    height: 250px;
  }
  .error-title {
    width: calc(100% - 20%);
    margin: 0 auto;
    text-align: center;
  }
}
