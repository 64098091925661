.favoritesItemsWrapper {
  height: 100%;
  .absoluteCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .favoritesItemsContainer {
    height: calc(100% - 50px);
    overflow: auto;
    border-radius: 8px;
    scrollbar-width: none;
    display: grid;
    grid-template-columns: repeat(4, calc((100% / 4) - 10px));
    grid-gap: 10px;
    align-content: baseline;

    .itemContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      background-color: #fff;
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      padding: 17px 20px;
      height: 100%;
      cursor: pointer;

      .patientFavorite {
        width: 50px;
        height: 50px;
        border-radius: 8px;
      }

      .patientTitle {
        font-family: "SF UI Display Medium", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        color: #b9b9b9;
      }

      .patientFullName {
        font-family: "SF UI Display Medium", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #272d2d;
      }

      .patientFooter {
        margin-top: auto;
        width: 100%;
        height: 20px;
        display: flex;
        justify-content: space-between;

        .idBlock {
          font-family: "SF UI Display Medium", sans-serif;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;

          .itemIdTitle {
            color: #b9b9b9;
            margin-right: 10px;
          }

          .itemIdValue {
            color: #272d2d;
          }
        }
      }
    }
  }
}
