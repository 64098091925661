.feedbacksListContainer {
  width: 100%;
  max-width: 960px;
  padding-right: 20px;
  // border: 1px solid black;
  position: relative;
  .absoluteCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .titleContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 30px;
    .title {
      font-family: "SF UI Display Medium", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 38px;
    }
  }
}
.feedbacksItemsContainer {
  position: relative;
  width: 100%;
  overflow: auto;
  height: calc(100% - 120px);
  border-radius: 8px;
  // border: 1px solid green;
  .feedbacksItem {
    // display: flex;
    width: 100%;
    padding: 15px 20px;
    // height: 1px;
    min-height: 131px;
    background: #ffffff;
    border-radius: 8px;
    margin-bottom: 20px;
    transition: 0.15s ease-in-out;
  }
}
.noFeedbacks {
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  & h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 38px;
  }
}

.date {
  margin-bottom: 5px;
  margin-left: 5px;
}

.info-box {
  width: 100%;
  // display: flex;
  // align-items: center;
  display: grid;
  grid-template-columns: repeat(5, 1fr);

  margin-bottom: 10px;

  font-family: "SF UI Display Medium", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: rgba(39, 45, 45, 0.3);
  & .section {
    width: 27%;
    &:first-child {
      width: 10%;
    }
  }
}

// .info-box > div:not(:last-child) {
//   margin-right: 50px;
// }

.doctor-box,
.patient-box {
  display: flex;
  flex-direction: column;
}

.idInfo,
.fullName,
.time {
  margin-top: 6px;

  font-family: "SF UI Display Medium", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: rgb(39, 45, 45);
}

.stars {
  min-width: 105px;
  margin-left: auto;
  margin-right: 0;
}

.comment-text {
  font-family: "SF UI Display Medium", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.time-desc > p {
  margin: 0;
}

.paginatorContainer {
  position: absolute;
  left: 0;
  bottom: 0;
}
