.addressModal {
  position: absolute;
  min-width: 380px;
  width: auto;
  top: 50px;
  border-radius: 8px;
  background: white;
  // padding: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99999999;
  -webkit-box-shadow: 2px 2px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 2px 2px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 2px 2px 8px 0px rgba(34, 60, 80, 0.2);
  .addressOption {
    // padding: 10px;
    padding: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    // margin-top: 8px;
    width: 100%;
    display: flex;
    color: black;
    // margin-bottom: 8px;
    border-bottom: 1px solid #e7e7e7;
    // border-bottom: 1px solid lightgrey;
    .optionName {
      font-family: "SF UI Display Medium", sans-serif;
      font-size: 17px;
      margin-right: 10px;
      // text-decoration: underline;
    }
    .optionValue {
      font-family: "SF UI Display Medium", sans-serif;
      font-size: 17px;
      color: #4b9bf5;
    }
  }
}
