.promocodesContainer {
  width: 100%;
  .promocodesListContainer {
    width: 100%;
    position: relative;
    max-width: 960px;
    padding-right: 20px;
    overflow: auto;

    .title {
      margin-bottom: 30px;
      align-items: center;

      .pageTitle {
        font-family: "SF UI Display Medium", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 38px;
        margin: 0;
      }

      .promocodesCountContainer {
        width: 50%;
        display: flex;
        justify-content: flex-end;

        .titleItem {
          width: 200px;
          display: flex;
          justify-content: center;
          //font-size: 12px !important;
          border: 1px solid #4b9bf5;
          background: #4b9bf5;
          color: white;
          border-radius: 15px;
          height: 30px;
          align-items: center;
          padding: 0 15px;
          margin-right: 10px;
        }
      }
      //.btn {
      //  max-width: 310px;
      //  height: 38px;
      //}
    }
  }

  .errorMsg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;
    color: #b9b9b9;
  }

  //.promoCodesAddictedFormWrapper {
  //  position: absolute;
  //  top: 105px;
  //  right: calc(0% - 425px);
  //  transition: 0.5s ease-in-out;
  //  width: 100%;
  //  max-width: 425px;
  //  //   height: 85vh;
  //  background: white;
  //  padding: 20px;
  //  border-radius: 12px;
  //
  //  &.showing {
  //    @extend .promoCodesAddictedFormWrapper;
  //    right: 0;
  //    top: 105px;
  //    transition: 0.5s ease-in-out;
  //    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  //  }
  //
  //  .formContent {
  //    display: grid;
  //    grid-template-columns: 1fr 1fr;
  //    grid-gap: 15px;
  //    align-items: center;
  //    justify-content: center;
  //
  //    .promoCodesCounterLabel {
  //      margin-right: 15px;
  //    }
  //  }
  //
  //  .btn {
  //    width: 90%;
  //    height: 38px;
  //    text-align: center;
  //    justify-content: center;
  //    align-items: center;
  //  }
  //
  //  .btn-add {
  //    background: #4b9bf5;
  //    margin-right: auto;
  //  }
  //
  //  .btn-cncl {
  //    background: #fd3845;
  //    margin-left: auto;
  //  }
  //}
}
