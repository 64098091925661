.new-division-form-container {
  height: 100%;

  .form-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #272d2d;
    margin: 0px 0px 30px 0px;
  }

  .new-division-form {
    height: calc(100% - 54px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .form-content {
      height: 100%;
      overflow: scroll;
      .dateOfBith {
        position: relative;
        width: 100%;
        max-width: 320px;
        display: flex;
        align-items: center;
        height: 77px;
        .formField,
        .picker {
          width: 100%;
          .rdt,
          .datePicker {
            width: 100%;
            max-width: 100%;
            background: #f6f7fb;
            border-radius: 8px;
            input[type="text"] {
            }
          }
        }
        .closeImg {
          position: absolute;
          top: 16px;
          right: 40px;
        }
        .calendarImg {
          position: absolute;
          top: 16px;
          right: 10px;
        }
      }
    }

    .form-footer {
    }
  }
}
